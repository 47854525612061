import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"faq"} />
		<Helmet>
			<title>
				ClearPath
			</title>
			<meta name={"description"} content={"Your Cleaning Expert"} />
			<meta property={"og:title"} content={"ClearPath"} />
			<meta property={"og:description"} content={"Your Cleaning Expert"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65e71749009abe001f042f63/images/1-3.jpg?v=2024-03-06T09:51:00.290Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65e71749009abe001f042f63/images/1-3.jpg?v=2024-03-06T09:51:00.290Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65e71749009abe001f042f63/images/1-3.jpg?v=2024-03-06T09:51:00.290Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65e71749009abe001f042f63/images/1-3.jpg?v=2024-03-06T09:51:00.290Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65e71749009abe001f042f63/images/1-3.jpg?v=2024-03-06T09:51:00.290Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65e71749009abe001f042f63/images/1-3.jpg?v=2024-03-06T09:51:00.290Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65e71749009abe001f042f63/images/1-3.jpg?v=2024-03-06T09:51:00.290Z"} />
		</Helmet>
		<Components.Header />
		<Section padding="100px 0 100px 0" background="--color-dark" quarkly-title="FAQ-7">
			<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" color="--light">
				Frequently Asked Questions
			</Text>
			<Text margin="0px 0px 70px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#b2bac1" sm-margin="0px 0px 50px 0px">
				If you are considering ClearPath Cleaning Services for your cleaning needs, we have compiled answers to some of the most common questions. Our goal is to make your path to a cleaner space as smooth and transparent as possible.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="50px 50px"
				lg-grid-template-columns="repeat(2, 1fr)"
				md-grid-template-columns="1fr"
				sm-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						What types of cleaning services do you offer?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#b2bac1">
						We provide a wide range of cleaning services: from standard home and office cleaning to deep cleaning, move-in/move-out cleaning, and eco-friendly options.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Do you use environmentally safe cleaning products?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#b2bac1">
						Yes, we prioritize the health of our clients and the planet by using eco-friendly cleaning products that are effective and safe for all types of spaces.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Can I schedule cleaning outside of regular hours?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#b2bac1">
						Absolutely! We offer flexible scheduling to accommodate your busy life, including evening and weekend appointments upon request.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						How long does a standard cleaning take?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#b2bac1">
						The duration of a cleaning session varies depending on the size of your space and the type of service requested. We strive to work efficiently while ensuring each cleaning meets our high standards.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Do you offer one-time cleaning services or only regular cleaning?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#b2bac1">
						We provide both one-time cleaning and the option to schedule regular cleaning based on your preferences and needs.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text margin="0px 0px 15px 0px" font="normal 500 25px/1.2 --fontFamily-sans" color="--light">
						Are your cleaners insured and bonded?
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 16px/1.5 --fontFamily-sansHelvetica" color="#b2bac1">
						Yes, all our cleaners are fully insured and bonded, giving you peace of mind knowing your property is in reliable and professional hands.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65dde662009abe001f03e3dd"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});